.sectionTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin: 0 auto;
  margin-bottom: 44px;
  text-align: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tipSection {
  background: rgba(255, 255, 255, 0.52);
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.tipSection {
  display: flex;
  justify-content: center;
}

.slide-img {
  width: 100%;
  max-height: 100%;
}

.dotContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 24px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 99999px;
  background: #d9d9d9;
  margin: 0 4px;
  cursor: pointer;
}

.dot.active {
  background: #8999fe;
}

.arrow {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
}
.arrow.left {
  right: 32px;
}

.arrow.right {
  transform: rotate(180deg);
  left: 32px;
}
.stepContainer {
  padding-left: 104px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.stepBox {
  position: relative;
  cursor: pointer;
}

.stepBox.active .step,
.stepBox.active .stepContent {
  color: #8999fe;
}

.stepBox.active {
  transform: scale(1.42);
  transform-origin: left;
}

.step {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #13141a;
}

.stepContent {
  margin-left: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.stepCurrentTag {
  position: absolute;
  width: 12px;
  height: 10px;
  top: 7px;
  left: -18px;
}

.maxContent {
  max-width: 970px;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.relLink {
  margin-top: 40px;
  border-top: 1px solid #e8e8e8;
  padding-top: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.relLink a {
  text-decoration: none;
  color: rgba(134, 151, 255, 1);
}

.tipSection.seed {
  background-color: transparent;
  padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .tipSection {
    align-items: center;
    height: auto;
    padding: 0;
  }

  .sectionTitle {
    min-width: inherit;
    max-width: inherit;
    font-size: 16px;
    margin: 1rem 0;
  }

  .maxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .stepContainer {
    margin-top: 40px;
    padding-left: 0;
    max-width: calc(100vw - 2rem);
  }

  .stepBox {
    max-width: calc(100vw - 2rem);
    margin: 0.5rem;
  }

  .stepBox.active .step,
  .stepBox.active .stepContent {
    font-size: 16px;
  }

  .stepBox.active {
    transform: scale(1);
    transform-origin: left;
  }

  .step,
  .stepContent {
    font-size: 12px;
  }

  .stepCurrentTag {
    width: 7px;
    height: 6px;
    top: 9px;
    left: -10px;
  }
}
