.container {
  background: #000000;
  min-height: 100vh;
  --box-pl: 100px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.introBox {
  margin-top: 40px;
  .intro {
    background-color: #ffffff;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  background-color: #8697ff;
  background-repeat: no-repeat;
  background-size: 420px 120px;
  background-position: center center;
}

.logo {
  width: 36px;
  height: 36px;
  margin-right: 16px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .header {
    justify-content: center;
    height: auto;
    padding: 1rem;
    height: 60px;
    background-size: 210px 60px;
  }
  .logo {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
  }
  .introBox {
    margin-top: 1rem;
  }

  .title {
    max-width: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
}
